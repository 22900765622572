<template>
<v-app>
    <v-main>
      <LoginPanel @login="onLogin($event)" v-if="!loggedIn"/>
      <MainView v-else :client="client" />
    </v-main>
  </v-app>
</template>

<script>
import AdminClient from "./lib/admin_client";
import LoginPanel from "./components/login/LoginPanel.vue";
import MainView from "./components/MainView.vue";

export default {
  name: 'App',
  components: {
    LoginPanel,
    MainView
  },
  data: () => ({
    client: new AdminClient(this)
  }),
  created() {
    if(localStorage.getItem('pass')) {
      this.client.setPassword(localStorage.getItem('pass'));
    }
  },
  methods: {
    onLogin(password) {
      if(password.length > 0) {
        localStorage.setItem('pass', password);
        this.client.setPassword(password);
      }
    }
  },
  computed: {
    loggedIn() {
      return this.client.password !== undefined;
    }
  }
};
</script>

<style>
html {
  overflow-y: hidden;
}
</style>