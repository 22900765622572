<template>
  <div>
    <v-card v-if="contentType === undefined">
      <v-card-title> Admin Panel </v-card-title>
      <v-card-text>
        <v-btn @click="contentType = 'stats'"> Stats </v-btn>
        <v-btn @click="contentType = 'verify'"> Verify </v-btn>
      </v-card-text>
    </v-card>
    <template v-else>
      <StatsView
        v-if="contentType === 'stats'"
        :client="client"
        @back="contentType = undefined"
      />
      <VerifyView
        v-if="contentType === 'verify'"
        :client="client"
        @back="contentType = undefined"
      />
    </template>
  </div>
</template>

<script>
import StatsView from "./stats/StatsView.vue";
import VerifyView from "./verify/VerifyView.vue";

export default {
  name: "MainView",
  props: ["client"],
  data: () => ({
    contentType: undefined,
  }),
  components: {
    StatsView,
    VerifyView,
  },
};
</script>