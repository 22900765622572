export default class AdminClient {
    constructor(app) {
        this.app = app;
        this.password = undefined;
        this.host = location.protocol + '//' + location.host + '/server/';
    }
    setPassword(password) {
        this.password = password;
    }
    post(url, request) {
        this.loading = true;
        return new Promise((resolve, reject) => {
            const req = new XMLHttpRequest();
            req.addEventListener('load', () => {
                this.loading = false;
                try {
                    const parsed = JSON.parse(req.responseText);
                    if(parsed.success !== undefined && parsed.success === false) {
                        this.app.handleServerError(parsed);
                    }
                    console.log(parsed);
                    resolve(parsed);
                } catch(e) {
                    console.error(e);
                    reject(req.responseText);
                }
            });
            req.addEventListener('error', () => {
                reject(req.responseText);
            });
            req.open('POST', this.host + url);
            request.password = this.password;
            req.send(JSON.stringify(request));
        });
    }

    getStats() {
        return this.post('admin', {
            action: 'stats'
        });
    }

    getVerifyLists(count) {
        return this.post('admin', {
            action: 'verify-list',
            count
        })
    }

    verifyEntry(id, edit) {
        return this.post('admin', {
            action: 'verify',
            item: id,
            edit
        });
    }

    denyEntry(id) {
        return this.post('admin', {
            action: 'deny',
            item: id
        });
    }

}