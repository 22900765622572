<template>
    <v-card class="mx-2 my-2">
        <v-card-title>
            Login
        </v-card-title>
        <v-card-text>
            <v-text-field label="Password" type="password" v-model="password">
            </v-text-field>
            <v-btn @click="$emit('login', password)">
                Login
            </v-btn>
        </v-card-text>
    </v-card>
</template>

<script>
export default {
    name: 'LoginPanel',
    data: () => ({
        password: ''
    })
}
</script>