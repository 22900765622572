<template>
  <v-card class="mx-2 my-2">
    <v-card-title>
      <v-btn icon @click="$emit('back')"><v-icon>mdi-arrow-left</v-icon></v-btn>
      Verify
      <v-btn icon color="success" @click="refresh()">
        <v-icon>mdi-reload</v-icon>
      </v-btn>
    </v-card-title>
    <v-card-text>
        <v-row>
          <v-col>
            Brand
          </v-col>
          <v-col>
            Name
          </v-col>
          <v-col>
            Weight
          </v-col>
          <v-col>
            Action
          </v-col>
        </v-row>
        <v-chip v-if="items.length <= 0">
          No entries.
        </v-chip>
        <VerifyListEntry
          v-for="item of items"
          :key="item._id"
          :item="item"
          @confirm="confirmEntry(item, $event)"
          @deny="denyEntry(item)"
        />
    </v-card-text>
  </v-card>
</template>

<script>
import VerifyListEntry from "./VerifyListEntry.vue";

export default {
  name: "VerifyView",
  props: ["client"],
  components: {
    VerifyListEntry,
  },
  data: () => ({
    items: [],
  }),
  created() {
    this.refresh();
  },
  methods: {
    confirmEntry(original, edit) {
      console.log("Edit item", edit)
      this.client.verifyEntry(original._id, edit).then((response) => {
        if (response.success) {
          this.items = response.items;
        }
      });
    },
    denyEntry(item) {
      this.client.denyEntry(item._id).then((response) => {
        if (response.success) {
          this.items = response.items;
        }
      });
    },
    refresh() {
      this.client.getVerifyLists(20).then((response) => {
        if (response.success) {
          this.items = response.items;
        }
      });
    },
  },
};
</script>