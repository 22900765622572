<template>
    <v-row>
        <v-col>
            <v-text-field v-model="itemEdit.img" label="Image">
                <template slot="prepend">
                    <img :src="itemEdit.img" style="max-width: 2rem; max-height: 2rem;">
                </template>
            </v-text-field>
        </v-col>
        <v-col>
            <v-text-field v-model="itemEdit.brand" label="Brand">
            </v-text-field>
        </v-col>
        <v-col>
            <v-text-field v-model="itemEdit.name" label="Name">
            </v-text-field>
        </v-col>
        <v-col>
            <v-text-field v-model="itemEdit.weight" type="number" label="Weight">
            </v-text-field>
        </v-col>
        <v-col>
            <v-btn color="success" @click="$emit('confirm', itemEdit)" class="mr-1">
                Confirm
            </v-btn>
            <v-btn color="error" @click="$emit('deny')" class="mr-1">
                Deny
            </v-btn>
            <v-btn icon @click="search()">
                <v-icon>mdi-magnify</v-icon>
            </v-btn>
            <v-btn icon @click="resetEdit()">
                <v-icon>mdi-reload</v-icon>
            </v-btn>
        </v-col>
    </v-row>
</template>

<script>
export default {
    name: 'VerifyListEntry',
    props: ['item'],
    data: () => ({
        itemEdit: {}
    }),
    created() {
        this.resetEdit();
    },
    methods: {
        search() {
            let qryString = this.itemEdit.brand + '+' + this.itemEdit.name + '+weight';
            while(qryString.includes(' ')) qryString = qryString.replace(' ', '+');
            window.open(`https://www.google.com/search?q=${qryString}`, '_blank');
        },
        resetEdit() {
            this.itemEdit = {...this.item}; 
        }
    }
}
</script>